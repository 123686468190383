import React from 'react';
import { connect } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input
} from "reactstrap"
import axios from "utility/api"
import { assignLocker } from "redux/actions/orders"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import ModalSpinner from "components/@vuexy/spinner/Modal-spinner"
import { obfuscateName } from 'utility/string_filters'

class DeliverOrderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lockerSize: 'm',
      isSubmitting: false,
    }
  }

    setLockerSize = (lockerSize) => {
      this.setState({lockerSize: lockerSize})
    }

    requestLocker = () => {
      if(this.state.lockerSize != null){
        this.setState({isSubmitting: true})
        this.props.dispatch(assignLocker(this.props.accessToken, process.env.REACT_APP_RENTAL_LOCATION_ID, this.props.selectedOrder.packageId, this.state.lockerSize, true)).then(() => {
          this.setState({isSubmitting: false})
        }).catch((err) => {
          this.setState({isSubmitting: false})
          if (err === 'No locker available'){
            toast.error(this.props.t("notifications.deliverOrder.noLockersAvailable"))
          }else{
            toast.error(this.props.t("notifications.deliverOrder.errorRequestingLocker"))
          }
          this.props.toggleDeliveryModal()
        })
      }else{
        toast.error(this.props.t("notifications.deliverOrder.selectLockerSize"))
      }
    }

    openDoorAndDeliver = () => {
    this.setState({isSubmitting: true})
     axios.defaults.headers.common = {'Authorization': 'Bearer ' + this.props.accessToken}
     axios.get('api/delivery/storePackage?packageId='+this.props.selectedOrder.packageId)
     .then(res => {
       this.setState({isSubmitting: false})
       if(res.data.ResultCode === 1){
         toast.success(this.props.t("notifications.deliverOrder.doorOpenedSuccessfuly"))
         this.props.toggleDeliveryModal()
       }else{
         toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
       }
     })
     .catch(error => {
       this.setState({isSubmitting: false})
       toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
     })
    }

    render(){
      const { t, selectedOrder } = this.props

      return (
        <>
        <Modal
        isOpen={this.props.showDeliveryModal}
        toggle={this.props.toggleDeliveryModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader toggle={this.props.toggleDeliveryModal} className="bg-primary">
        {t('barcodeScanner.deliverOrder')}
        </ModalHeader>
        <ModalBody className="delivery-modal">
          {!selectedOrder &&
            <>
            <ModalSpinner />
            </>
          }
          {selectedOrder &&
            <>
              <p><strong>{t('orderDetails.packageId')}</strong><br />{selectedOrder.packageId}</p>
                <p><strong>{t('orderDetails.recipientName')}</strong><br />{obfuscateName(selectedOrder.recipientName)}</p>
              <p><strong>{t('orderDetails.recipientPhone')}</strong><br />{selectedOrder.recipientPhone}</p>
              <p className="mt-2">{t('barcodeScanner.lockerSize')}</p>
              <Input
                  type="select"
                  value={this.state.lockerSize}
                  onChange={(e) => this.setLockerSize(e.target.value)}
                  id="lockerSize"
                  className="mb-2"
                >
                <option value="s">S [{t("orders.available")}: {this.props.lockersBySize['S']}]</option>
                <option value="m">M [{t("orders.available")}: {this.props.lockersBySize['M']}]</option>
                <option value="l">L [{t("orders.available")}: {this.props.lockersBySize['L']}]</option>
              </Input>
              {!selectedOrder.assignedLocker.number &&
                <Button color="primary" block onClick={() => this.requestLocker()}>
                  {t('barcodeScanner.requestLocker')}
                </Button>
              }
              {selectedOrder.assignedLocker.number &&
                <>
                  <Button color="warning" className="mb-1" disabled={this.state.isSubmitting} block onClick={() => this.requestLocker()}>
                    {t('barcodeScanner.changeLocker')}
                  </Button>
                  <p><strong>{t('barcodeScanner.assignedLocker')}</strong></p>
                  <p className="delivery-assigned-locker-number mt-2 mb-2">{selectedOrder.assignedLocker.number}</p>
                  <p>({t('orderDetails.size')}: {selectedOrder.assignedLocker.size})</p>
                  <Button color="primary" disabled={this.state.isSubmitting} block onClick={() => this.openDoorAndDeliver()}>
                    {t('barcodeScanner.confirmAndDeliver')}
                  </Button>
                </>
              }
            </>
          }
        </ModalBody>
      </Modal>
        </>
      )
    }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
    selectedOrder: state.orders.selectedOrder,
    lockersBySize: state.locations.lockersBySize
  }
}

export default connect(mapStateToProps)(withTranslation('common')(DeliverOrderModal))
