export const sectionsList = ["Portuguese",]
export const schoolsList = ["SJS-P","Library",]
export const statusList = [[1,"active"],[0, "suspend"],]
export const classesList = ["6º","7º","8º","9º","10","11º",]

export const floorsList = ["a","b", "c"]
export const companiesList = ["Microsoft","Facebook",]
export const usernamesList = ["ROMEU","LOKK LAB 4",]
export const locationsList = ["OPEN","CITY CENTER",]
export const rolesList = [
  {
    value: "admin", 
    name: "Administrator"
  },
  {
    value: "security", 
    name: "Security"
  },
  {
    value: "store", 
    name: "Store"
  },
]

export const userRoles = [
  {
    name: 'Screen',
    role: 'ROLE_SCREEN'
  },
  {
    name: 'Dash',
    role: 'ROLE_DASH'
  },
  {
    name: 'Store',
    role: 'ROLE_STORE'
  }
]
export const userTypeList = [[1, "Employee"],[2, "Intern"],]
export const months = {
   1 : "Jan",
   2 : "Feb",
   3 : "Mar",
   4 : "Apr",
   5 : "May",
   6 : "Jun",
   7 : "Jul",
   8 : "Aug",
   9 : "Sep",
   10 : "Oct",
   11 : "Nov",
   12 : "Dec"
}
export const lockerSizeList = ['s', 'm', 'l']
export const orderStates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const orderListStatesFilter = [1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 13, 14]
export const assetStatusList = [1, 2, 3, 4, 5, 6]
export const assetTagStatusList = [0, 1]
export const assetLogsStatusList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
export const functionsList = [
  "Admnistrativos",
  "Administ. Delegado",
  "Auxiliares",
  "Dirigentes",
  "Enfermeiros",
  "Internos - Compl. FP",
  "Médicos",
  "Serviços gerais",
  "Téc. Diag. Terap.",
  "Téc. Sup. Saúde",
  "Téc. Superiores",
  "Outras"
]
export const servicesList = [
  "Não Aplicável",
  "Fotografia - Azul",
  "Fotografia - Verde",
  "Video",
  "Outro",
  
]
export const unitList = [
  "Não Aplicável",
  "Regular Fotografo",
  "Regular Jornalista",
  "Regular Vídeo",
  "Season",
  "Outro",
  
]
