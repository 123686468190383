const locationsReducer = (state = { ordersList : {orders: [], maxRows: 0}, archivedOrdersList : {archivedOrders: [], maxRows: 0}, selectedOrder: null, lockers: null }, action) => {
  switch (action.type) {
    case "GET_ORDERS_LIST_SUCCESS":
      return { ...state, ordersList: action.payload }
    case "GET_ARCHIVED_ORDERS_LIST_SUCCESS":
      return { ...state, archivedOrdersList: action.payload }
    case "GET_ORDER_DETAILS_SUCCESS":
      return { ...state, selectedOrder: action.payload }
    case "GET_ORDER_PLACES_LOCKERS":
      return { ...state, lockers: action.payload }
    case "GET_ORDERS_KPIS_SUCCESS":
      return { ...state, kpis: action.payload }
      case "GET_ORDERS_ANALYTICS_SUCCESS":
      return { ...state, analytics: action.payload }
    default:
      return state
  }
}

export default locationsReducer
